.saves_model_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  pointer-events: none;
  .saves_model_inner_container {
    z-index: 1001;
    width: 40rem;
    height: 35rem;
    background-color: #f2f2f2;
    border: 2px solid #636c79;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    padding: 1.25rem;
    position: relative;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem 1rem 0 0;
    padding: 0rem;
    background-color: transparent;
  }

  h1:first-of-type {
    margin-top: -1rem;
    font-size: 2.5rem;
  }

  .update_section {
    width: 100%;
    height: 12rem;
    border: 1px solid #d9d9d9;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .content_container {
    display: flex;
    flex-direction: column;
    max-width: fill-available;
    width: 12.8rem;
  }

  .card {
    background-color: #fff;
    border-radius: 4px;
    height: 5rem;
    width: 100%;
    padding: 0.5rem;
    display: flex;

    h1 {
      font-size: 0.8rem;
      margin: auto 0 0 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    label {
      font-size: 0.75rem;
      margin: 0.5rem 0 auto 0;
      pointer-events: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    img {
      width: 3.25rem;
      height: 3.25rem;
      margin: auto 0.25rem auto 0;
    }
  }

  .card.active {
    border: 2px solid #7f7f7f;
  }

  .card:hover {
    outline: 1px solid #d9d9d9;
    cursor: pointer;
  }

  .saves_name,
  .type_name,
  .update_section {
    margin: 0.5rem 0 0 0;
  }
  @media screen and (max-width: 768px) {
    .saves_model_inner_container {
      width: 22rem;
      height: 40rem;
    }
    .update_section {
      grid-template-columns: 1fr;
      height: 16.8rem;
    }
  }
}

.regurgitator_container {
  max-width: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;

  .reg_core_item_map {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.regurgitator_output {
  width: 100%;
}

.clear_button {
  display: flex;
  justify-content: center;
  img {
    width: 1.85rem;
    height: 1.85rem;
    margin: auto 0rem auto 0.5rem;
  }
  p {
    margin: auto 0rem auto 0rem;
    color: #fff;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap");
.landing_page_design {
  display: flex;
  position: relative;
  padding: 2rem;
  height: 100vh;

  .background_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
  }

  h1 {
    font-family: "Ubuntu", sans-serif;
    text-shadow: -4px 4px 4px rgba(0, 0, 0, 0.26);
    padding-left: 6rem;
    font-size: 4.25vw;
    margin: 0;
    /* font-size: 5rem; */
  }

  .headers_and_art {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto 0;
  }

  .subheaders_and_art {
    display: flex;
    position: relative;
  }

  h2 {
    position: absolute;
    top: -0.5em;
    right: 5em;
    font-size: 1.25vw;
    /* font-size: 20px; */
  }

  .illustration {
    width: 30vw;
    margin-top: 2.5em;
    margin-left: 2em;
  }

  .action_section {
    width: 50%;
    height: 100%;
    justify-content: center;
    gap: 2.5em;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  @media (max-height: 620px) {
    .action_section {
      gap: 1.5em;
    }
  }

  .action_card {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    border-radius: 4px;
    border: 1px solid #707070;
    justify-content: center;
    align-items: center;
    width: 375px;
    height: 160px;
    -webkit-box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 11em;
    justify-content: center;
    padding: 0 1em;
  }

  h3 {
    margin: 0;
    font-size: 1.5em;
    color: #707070;
    text-align: center;
    line-height: 1.2;
  }
}

@media (max-width: 1224px) {
  .landing_page_design {
    flex-direction: column;
    justify-content: center;
    gap: 2em;
    .illustration {
      width: 100%;
      margin: 0;
      max-width: 590px;
    }

    h1 {
      font-size: 10vw;
      margin: 0;
      padding: 0;
      line-height: 1.25;
    }

    h2 {
      position: static;
      font-size: 5vw;
    }

    button,
    h2,
    h1 {
      margin: 0;
    }
  }
}

@media (min-width: 676px) and (max-width: 1224px) {
  .landing_page_design {
    gap: 1em;
    h1 {
      font-size: 5vw;
    }

    h2 {
      font-size: 2vw;
    }
  }
}

@media (min-width: 429px) and (max-width: 1224px) and (max-height: 750px) {
  .landing_page_design {
    .illustration {
      max-width: 350px;
    }

    h1 {
      font-size: 4vw;
    }

    h2 {
      font-size: 3vw;
    }
  }
}
